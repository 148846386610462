import React, { useState, useEffect, useRef  } from "react"
import './Calculator.scss';

const ValuationTwo = (props) => {
    const iframeRef = useRef(null); 
    const iframeUrl = 'https://home.app.mortgageadvicebureau.com/?introducercode=dbroberts_digital_app&introducerupdate=true'      
    return (
        <React.Fragment>
        <div className="valuation-form mortgage-calc">
           <iframe src={iframeUrl} width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>            
        </div>
        </React.Fragment>
    )
}
export default ValuationTwo
