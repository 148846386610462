import { useStaticQuery, graphql } from "gatsby"
import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import SoldAuctionsList from "./SoldAuctionsList";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
// import { isTablet, isIPad13, isIOS13 } from 'react-device-detect';
import "./SoldAuctions.scss";
import $ from "jquery"

import { inViewOptions, containerVariants } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const postsPerPage = 16;
let arrayForHoldingPosts = [];

const SoldAuctions = (props) => {
  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(16);

  const data = useStaticQuery(graphql`
  query {
      glstrapi {
        soldAuctions (sort:"Sort:asc"){         
          description
          guide_price
          image {
            url
          }
          sold_price
          short_address
          _id
          }
        }
      }
`);
  const soldAuction = data.glstrapi.soldAuctions;

  const loopWithSlice = (start, end) => {
    const slicedPosts = soldAuction.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    console.log("soldAuction",arrayForHoldingPosts)
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };

  useEffect(() => {
    loopWithSlice(0, postsPerPage);
  }, []);
  //console.log("props", soldAuction)
  return (
    <>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="static-gallery sold-auction-gallery"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <Container>
            <Row>
              <div className="gallery-wrapper">               
                  {  postsToShow.map((item, index) => (
                    <Col className="cardList" lg={3} sm={6} xs={12} key={index}>
                      <SoldAuctionsList 
                      description={item?.description}
                      guide_price={item?.guide_price}
                      sold_price={item?.sold_price}
                      short_address={item?.short_address}
                      GalleryImg={item?.image}
                      GalleryName={item?.short_address}
                      custom={index} />
                    </Col>
                  ))  
                  }                
              </div>
              {arrayForHoldingPosts.length !== soldAuction.length ?
                       <div className="col-md-12">
                        <div className="pagination-btn">
                        <button className="btn btn-pagination" onClick={handleShowMorePosts}>Load more</button>
                        </div></div> : ''
              }
              </Row>
            </Container>
          </motion.section>
        )}
      </InView>
    </>
  );
};
export default SoldAuctions;
