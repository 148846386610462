import React, { useState, Fragment, useRef, useEffect } from "react";
import { useMatch } from "@reach/router"
import Header from "./Header/Header";
import Breadcrumbs from "./Footer/Breadcrumb/Breadcrumb";
// import Footer from "./Footer/Footer";
import { Container, Row, Col } from "react-bootstrap"
import BannerSection from "./Home/Banner/Banner";
import VideoBanner from "./Home/Banner/VideoBanner";
import StaticBanner from "./StaticPage/StaticBanner/StaticBanner";
import Getproperty from "./Home/Getproperty/Getproperty";
// import ServiceSection from "./Home/OurServices/OurServices";
import OurStory from "./Home/OurStory/OurStory";
import ServiceCards from "./Home/ServiceCards/ServiceCards";
import AboutSection from "./Home/AboutSection/AboutSection";
import SearchForm from "./Home/SearchForm/SearchForm";
import FeaturedProperties from "./GuideDetails/FeaturedProperties/FeaturedProperties";
// import Reviews from "./Home/Reviews/Reviews";
// import News from "./Home/News/News";
import AllNews from "./Home/News/AllNews";
import NewsDetail from "./Home/News/NewsDetail";
// import VideoDetail from "./Home/Videos/VideoDetail";
import TeamDetail from "./TeamDetails/TeamDetails";
import Clients from "./StaticPage/OurClients/OurClients";
import Videos from "./Home/Videos/Videos";
import AllMembers from "./TeamDetails/TeamMember/AllMembers";
import TeamHeader from "./TeamDetails/TeamMember/TeamHeader";
import Sidebar from "./Sidebar/Sidebar";
import PropertyValuation from "./PropertyValuation/PropertyValuation";
import PropertyList from "./Home/PropertyList/PropertyList";
import RequestForm from "./StaticPage/RequestForm/RequestForm";
import Magazine from "./StaticPage/Magazine/Magazine";
import MagazineDetail from "./StaticPage/Magazine/MagazineDetail";
import CareerForm from "./Careers/Form/CareerForm";
import ValuationForm from "./ValuationForm/ValuationForm";
import MortageCalculator from "./MortageCalculator/Calculator";
import OfficeGallery from "./StaticPage/OfficeGallery/OfficeGallery";
import SoldAuctions from "./StaticPage/SoldAuctions/SoldAuctions";
import ContentWithSidebar from "./GuideDetails/PlainContent"
import StickyFooter from "./Footer/StickyFooter"
import SocialWall from "./Social";
import Feefo from "./StaticPage/Feefo"
import Map from "./map/office-details-map";
import { InView } from 'react-intersection-observer';
import { LATEST_NEWS_PAGE_URL, MEET_THE_TEAM_PAGE_URL, MAGAZINE_PAGE_URL, VIDEOS_PAGE_URL, VIDEO_DETAIL_PAGE_URL } from "./Common/site/constants";
import _ from "lodash";
import $ from "jquery";
import AreaGuideList from "./Home/AreaGuidesList";
import TeamStories from "./Careers/TeamStories";
import Loadable from "@loadable/component"


const News = Loadable(() => import("./Home/News/News"))
const ServiceSection = Loadable(() => import("./Home/OurServices/OurServices"))
const Footer = Loadable(() => import("./Footer/Footer"))
const Reviews = Loadable(() => import("./Home/Reviews/Reviews"))

const Modules = (props) => {
    const myRef = useRef(null)
    useEffect(() => {
        $(document).on("click", ".feefowidget-pagination-item", function () {

            $('html, body').animate({
                scrollTop: $("#scroll_view").offset().top
            }, 400);
        });
    }, [])
    const [showMenu, setShowMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [videoCategory, setVideoCategory] = useState(null);
    const [teamCategory, setTeamCategory] = useState("all_departments");
    const [officeCategory, setOfficeCategory] = useState("all_offices");
    const [bannerInView, setBannerInView] = useState(true);
    const handlerClick = () => {
        var root = document.getElementsByTagName('html')[0];
        setShowMenu(!showMenu)
        if (showMenu == true) {
            document.body.className = '';
            root.className = '';
        } else {
            document.body.classList.add("overflow-hidden");
            root.classList.add("overflow-hidden");
        }
    }

    const handlerSearchClick = () => {
        setShowSearch(!showSearch)
        setShowMenu(false)
        if (showSearch == true) {
            document.body.className = '';

        } else {
            document.body.classList.add("overflow-hidden");
        }
    }

    const handlerSelect = (selected) => {
        setVideoCategory(selected.value)
    }
    const teamhandlerSelect = (selected) => {
        setTeamCategory(selected.value)
    }
    const officehandlerSelect = (selected) => {
        setOfficeCategory(selected.value)
    }
    const NewsDetails = useMatch(LATEST_NEWS_PAGE_URL.alias + "/:item");
    const MagazineDetails = useMatch(MAGAZINE_PAGE_URL.alias + "/:item");
    const TeamDetails = useMatch(MEET_THE_TEAM_PAGE_URL.alias + "/:item");
   const VideoDetails = useMatch(VIDEO_DETAIL_PAGE_URL.alias + "/:item");
   const VideosPage = useMatch(VIDEOS_PAGE_URL.alias + "/:item");
    
    useEffect(() => {
        const pageurl = typeof window !== 'undefined' ? window.location.href : ''
        let myid_filt = pageurl.split('#');
        if (!_.isEmpty(myid_filt[1])) {
            if (myid_filt[1] === "lettings") {
                setTeamCategory("lettings")
            }
        }
        if(VideosPage && videoCategory === null){
            setVideoCategory(VideosPage.item) 
        }
       
    }, [])
 
    return (
        <div className={`${(showMenu || showSearch) ? "wrapper open-search-block" : "wraapper dd"} ${props.page.Custom_CSS_Class ? props.page.Custom_CSS_Class : ''}`}>
            <Header showMenu={showMenu} handlerClick={handlerClick} handlerSearchClick={handlerSearchClick} showSearch={showSearch} fixed={true} />
            {props.page && props.page.Layout == "Default" && NewsDetails === null && VideoDetails === null && TeamDetails === null && MagazineDetails === null &&
                <InView as="div" onChange={(inView, entry) => setBannerInView(inView)}>
                    <BannerSection page={_.omit(props.page, "Modules")} lazyLoad={props.lazyLoad} path={props.path} />
                </InView>
            }
            {props.page && props.page.Layout == "Static_Page" && NewsDetails === null && VideoDetails === null && TeamDetails === null && MagazineDetails === null &&
                <InView as="div" onChange={(inView, entry) => setBannerInView(inView)}>
                    <StaticBanner page={_.omit(props.page, "Modules")} handlerSelect={handlerSelect} category={VideosPage?.item} />
                </InView>
            }
            {props.page && props.page.Layout == "Static_Page" && NewsDetails !== null &&
                <NewsDetail slug={NewsDetails.item} location={props.location} />
            }
            {/* {props.page && props.page.Layout == "Static_Page" && VideoDetails !== null &&
                <VideoDetail slug={VideoDetails.item} location={props.location} />
            }
             */}
            {props.page && props.page.Layout == "Static_Page" && MagazineDetails !== null &&
                <MagazineDetail slug={MagazineDetails.item} />
            }
            {props.page && props.page.Layout == "Static_Page" && TeamDetails !== null && 
                <TeamDetail slug={TeamDetails.item} popularSearch={props.page.Popular_Search} location={props.location}  />
            }
            {props.page.Show_Sidebar &&
                <section className="guide-details">
                    <Container>
                        <Row>
                            <Col xl={8} lg={7}>
                                {props.page.Banner_Image &&
                                    <VideoBanner {...props} />
                                }
                                {props.modules && props.modules.map((each, i) =>
                                    <Fragment key={i}>
                                        {each.type == "GLSTRAPI_ComponentModulesContentWithMultipleImages" &&
                                            <OurStory {...each} />
                                        }
                                    </Fragment>
                                )}
                            </Col>
                            <Col xl={4} lg={5}>
                                <Sidebar type="static" />
                            </Col>
                        </Row>
                    </Container>
                </section>
            }
            {NewsDetails === null && TeamDetails === null && MagazineDetails === null && props.modules && props.modules.map((each, i) =>
                <Fragment key={i}>
                    {(each.Content_with_Image && each.Top_Content !== "") &&
                        <ServiceSection {...each} lazyLoad={props.lazyLoad} />
                    }
                    {(each.Intro_Title && each.Intro_Content) &&
                        <AboutSection {...each} layout={props.page.Layout} />
                    }
                    {each.Add_service_Tile &&
                        <ServiceCards list={each.Add_service_Tile} />
                    }
                    {each.Reviews_Background_Image &&
                        <Reviews {...each} />
                    }
                    {each.Title_With_Collection == "Search_Form" &&
                        <SearchForm {...each} />
                    }
                    {(each.Title_With_Collection == "Featured_Properties" || each.Title_With_Collection == "Featured_Properties_Sale" || each.Title_With_Collection == "Featured_Properties_Rent") &&
                        <FeaturedProperties {...each} />
                    }

                    {each.Title_With_Collection == "Clients" &&
                        <Clients {...each} />
                    }

                    {each.Title_With_Collection == "Magazines" &&
                        <Magazine {...each} />
                    }

                    {props.lazyLoad && each.Title_With_Collection == "News" &&
                        <News {...each} />
                    }
                    {each.Title_With_Collection == "Teams" &&
                        <TeamStories {...each} />
                    }
                    {each.Collection == "News" &&
                        <AllNews />
                    }
                     {each.Collection == "SoldAuctions" &&
                        <SoldAuctions {...each} />
                    }
                    {each.Collection == "Offices" &&
                        <OfficeGallery {...each} />
                    }
                    {each.Collection == "Feefo_Reviews" &&
                        <>
                            <div ref={myRef} id="scroll_view"></div>
                            <Feefo />
                        </>
                    }
                    {each.Collection == "Social" &&
                        <SocialWall />
                    }
                    {each.Collection == "Videos" && VideoDetails == null &&
                        <Videos category={videoCategory} />
                    }
                    {each.Collection == "Teams" &&
                        <div className="team-landing-page">
                            <TeamHeader category={teamCategory} teamhandlerSelect={teamhandlerSelect} officehandlerSelect={officehandlerSelect} />
                            <AllMembers category={teamCategory} office_category={officeCategory} />
                        </div>
                    }
                    {each.Collection == "AreaGuides" &&
                        <AreaGuideList />
                    }
                    {each.Valuation &&
                        <PropertyValuation {...each} />
                    }
                    {each.Add_Service_Content &&
                        <PropertyList {...each} />
                    }

                    {each.Block_Content &&
                        <ContentWithSidebar {...each} />
                    }

                    {each.Form_Name === "Request_Form" &&
                        <RequestForm {...each} />
                    }
                    {each.Form_Name === "Careers_Form" &&
                        <CareerForm {...each} />
                    }
                    {each.type == "GLSTRAPI_ComponentModulesFooterBlock" &&
                        <Getproperty {...each} />
                    }
                    {each.type == "GLSTRAPI_ComponentModulesMap" && each.Latitude && each.Longtitude &&
                        <Map  {...each} lat={each.Latitude ? parseFloat(each.Latitude) : ''} log={each.Longtitude ? parseFloat(each.Longtitude) : ''} />
                    }
                    {(each.Form_Name === "Book_Your_Valuation" || each.Form_Name === "Instant_Valuation" || each.Form_Name === "Book_A_Valuation") &&
                        <ValuationForm {...each} />
                    }
                    {(each.Form_Name === "Mortgage_Calculator") &&
                        <MortageCalculator {...each} />
                    }
                </Fragment>
            )}
            {NewsDetails === null && TeamDetails === null && MagazineDetails === null && VideoDetails === null &&
                <Breadcrumbs tag={null} category={null} subCategory={null} data={props.page.Choose_Menu ? props.page.Choose_Menu[0] : ''} page={props.page.Pagename} />
            }
            {TeamDetails == null &&
                <Footer popularSearch={props.page.Popular_Search} popularSearchType="static" />
            }
            <StickyFooter page={_.omit(props.page, "Modules")} bannerInView={bannerInView} lazyLoad={props.lazyLoad} path={props.path} />




        </div>
    )
}
export default Modules
