import { Link } from "@StarberryUtils";
import React, { useState } from "react"
import { Container, Row, Col } from 'react-bootstrap';
import ModalTiny from "react-bootstrap/Modal"

import './GuideDetails.scss';
import HTMLReactParser from "html-react-parser";
import { GetURL } from "../Common/site/functions"
import RequestNewHomeForm from "../Forms/request-new-home-form"
import EnquiryForm from "../Forms/enquiry-form";
import ReachUsForm from "../Forms/react-us-form";
import _ from "lodash"

import { inViewOptions, containerVariants, fadeInFromRight } from '../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const PlainContent = (props) => {
    let url = ''
    if (props.CTA_Link) {
        url = GetURL(props.CTA_Link.id)
    }

    const [modalRequestformOpen, setRequestformOpen] = React.useState(false);
    const [modalEnquiryFormOpen, setEnquiryFormOpen] = React.useState(false);
    const [people, setPeople] = useState("");
    const [peopleNumber, setPeopleNumber] = useState("");
    const [modalContactformOpen, setContactformOpen] = React.useState(false);
    const closeContactformModal = () => {
        setContactformOpen(false);
    }

    const openContactformModal = (people, peopleNumber) => {
        setPeople(people)
        setPeopleNumber(peopleNumber)
        setContactformOpen(true);
    }

    const closeRequestformModal = () => {
        setRequestformOpen(false);
    }
    const closeEnquiryFormModal = () => {
        setEnquiryFormOpen(false);
    }
    const openForm = (formName) => {
        if (formName === "Request_New_Home") {
            setRequestformOpen(true);
        } else if (formName === "Request_a_Mortgage") {
            setEnquiryFormOpen(true)
        }
    }
    const officeName = (item) => {
        if (_.toLower(item.Designation) === "branch manager" || _.toLower(item.Designation) === "area manager") {
            return _.join(_.map(item.offices, 'Name'), ' & ');
        }
    }

    return (
        <>
            <InView {...inViewOptions}>
                {({ ref, inView }) => (
                    <motion.section
                        className="guide-details plain-content-with-sidebar"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <Container>
                            <Row>
                                {props.Block_Title &&
                                    <Col xl={12} lg={12}>
                                        <div className="guide-info">
                                            <span className="sm-text">
                                                {props.Block_Title}
                                            </span>
                                        </div>
                                    </Col>
                                }
                                <Col xl={8} lg={7}>
                                    <div className="guide-info">
                                        {props.Block_Content &&
                                            HTMLReactParser(props.Block_Content.replace(/<br\s*\/?>/gi, ''))
                                        }
                                    </div>
                                    {/* {props.CTA_Label && props.CTA_Link &&
                                        <Link to="" className="btn btn-outline">{props.CTA_Label}</Link>
                                    } */}
                                    {(props.CTA_Label && url[1] === "Internal") &&
                                        <Link to={`/${url[0]}`} className="btn btn-outline">{props.CTA_Label}</Link>
                                    }
                                    {(props.CTA_Label && url[1] === "External") &&
                                        <a href={`${url ? url[0] : ''}`} target="_blank" className="btn btn-outline">{props.CTA_Label}</a>
                                    }
                                    {(props.CTA_Label && props.CTA_Form) &&
                                        <button onClick={() => { openForm(props.CTA_Form) }} className="btn btn-outline">{props.CTA_Label}</button>
                                    }
                                </Col>

                                <Col xl={4} lg={5} className="overflow-hidden">
                                    <motion.div className="block-sticky" variants={fadeInFromRight}>
                                        <div className="help  pt-lg-0 pt-5">
                                            {props.Sidebar_Content &&
                                                <>
                                                    {HTMLReactParser(props.Sidebar_Content.replace(/<br\s*\/?>/gi, ''))}
                                                    {/* <a href="tel:01952291722" className="static-contact"><i className="icon-phone"></i> 01952 291722 </a> */}
                                                </>
                                            }
                                            {props.People_to_Contact && props.People_to_Contact.map((item, i) => {
                                                return (
                                                    <div className="agent-info" key={i}>
                                                        <Link className="agent-img" to={`/contact/our-team/${item.URL}`}>
                                                            <img src={item.Tile_Image.url} alt={`${item.Name} ${item.Designation} - DB Roberts`} />
                                                        </Link>
                                                        <div className="agent-details">
                                                            <h3><Link to={`/contact/our-team/${item.URL}`}>{item.Name}</Link></h3>
                                                            <span className="profile">{officeName(item)} {item.Designation}</span>
                                                            {/* <a
                                                                href={`javascript:void(0)`}
                                                                className="team-member-email"
                                                                onClick={() => { openContactformModal(item.Name, item.Phone) }}
                                                            >
                                                                {item.Email}
                                                            </a> */}
                                                            {item.Phone &&
                                                                <a href={`tel:+44${item.Phone}`} className="tel">{item.Phone}</a>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </motion.div>
                                </Col>
                            </Row>
                        </Container>
                    </motion.section>
                )}
            </InView>
            <ModalTiny size="lg" show={modalRequestformOpen} onHide={closeRequestformModal} className="modal-form">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Request Information</h2></ModalTiny.Title>
                </ModalTiny.Header>
                <ModalTiny.Body className="event-section"><RequestNewHomeForm title="Request Information" /></ModalTiny.Body>
            </ModalTiny>
            <ModalTiny size="lg" show={modalEnquiryFormOpen} onHide={closeEnquiryFormModal} className="modal-form">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Request a mortgage call back</h2></ModalTiny.Title>
                </ModalTiny.Header>
                <ModalTiny.Body className="event-section"><EnquiryForm title="Request a mortgage call back" /></ModalTiny.Body>
            </ModalTiny>
            <ModalTiny size="lg" show={modalContactformOpen} onHide={closeContactformModal} className="modal-form">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Contact {people}</h2></ModalTiny.Title>
                </ModalTiny.Header>
                <ModalTiny.Body className="event-section"><ReachUsForm title={`Contact ${people}`} office={""} people_name={people} people_number={peopleNumber} email_temp_user="individual_contact_user" /></ModalTiny.Body>
            </ModalTiny>
        </>
    )
}

export default PlainContent;