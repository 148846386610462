import React from "react"
import PlayVideo from "../../Play/CustomVideo";

const HomeBanner = (props) => {
    return (
        <React.Fragment>
            <div className="history news-banner">
                <div className="banner-img img-zoom">
                    <picture>
                        <source media="(min-width:992px)" srcSet={props.page.Banner_Image.url} />
                        <source media="(min-width:768px)" srcSet={props.page.Banner_Image.url} />
                        <img src={props.page.Banner_Image.url} alt={props.page.Banner_Image.alternativeText} />
                        {
                            props.page.Banner_Video_URL &&
                            <PlayVideo 
                            url={props.page.Banner_Video_URL}
                            icon="icon-play"
                            class="play-btn"
                            autoplay={props.page.AutoPlay}
                            repeat={props.page.Repeat}
                            buttonName="Play" />
                        }
                    </picture>
                </div>
            </div>
        </React.Fragment>

    )
}



export default HomeBanner