import { Link } from "@StarberryUtils";
import React, { useState } from "react";
import PlayVideo from "../../Play/CustomVideo";
import HTMLReactParser from 'html-react-parser';
import Slider from "react-slick";
import { delayItemsFromBottoms } from '../../Utils/animations';
import { motion } from "framer-motion";
//import { ShowProcessedImage } from '../../common/ggfx-client/module/components/show-image';
//import ImageTransform from "../../common/ggfx-client/module/components/image-transform"
//import imageConfig from "../../../static/images/config.json"
const SoldAuctionsList = (props) => {

  let [isPlay, setPlay] = useState(false);

  const settings = {
    dots: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    mobileFirst: true,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1402,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 377,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <motion.div variants={delayItemsFromBottoms} custom={props?.custom}>
      <div className="gallery-img img-zoom">       
          <div className="gallery-pic">
            <span className="sold-out">SOLD</span>
            <Slider className="slick-arrow" {...settings}>
                { props.GalleryImg?.map((image, i) => {
                  return (
                    <div className="slide-img img-zoom">
                      <img src={image.url} className="p-0" alt={`${props.GalleryName}`} />
                    </div>
                  )
                })}
               </Slider>
          </div>       
      
        <div className="gallery-content">
         
          <span className="mail_address description">
            {props.description &&
              HTMLReactParser(props.description)
            }
          </span>
          <h2 className="short_address">{props.short_address &&
              HTMLReactParser(props.short_address)
            }</h2>
            <span className="mail_address guide_price">
            {props.guide_price &&
              HTMLReactParser(`Guide Price ${props.guide_price}`)
            }
          </span>
          <span className="mail_address sold_price">
            {props.sold_price &&
              HTMLReactParser(`Sold Price ${props.sold_price}`)
            }
          </span>         
        </div>
      </div>
    </motion.div>
  );
};

export default SoldAuctionsList;
